<template>    
    <div class="content-wrapper">
        <bo-page-title></bo-page-title>

        <div v-if="isList" class="content pt-0">
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group mb-0">
                                            <div class="input-group">
                                                <date-range-picker
                                                    ref="picker"
                                                    :locale-data="datePickerConfig.locale"
                                                    :autoApply="datePickerConfig.autoApply"
                                                    v-model="dateRange"
                                                    :opens="'right'"
                                                    :ranges="datePickerConfig.ranges"
                                                    @update="updateValues"
                                                >
                                                    <template v-slot:input="picker">
                                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                    </template>
                                                </date-range-picker>
                                                <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row gx-1">
                                <div class="col-md">
                                    <div class="row gx-1">
                                        <div class="col-md-auto">
                                            <div class="form-group mb-0">
                                                <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Rujukan -- " @input="doFill" v-model="filter.status_rujukan"  :options="Config.mr.rujukanMasukStatus" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>
                                        
                                        <!--
                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>
                                        -->

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>     

                         
                
                <div class="tab-content">
                    <div :class="'tab-pane fade show active'">
                        <div class="table-responsive">
                            <table
                                class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                                <thead>
                                    <tr>
                                        <th>NO.</th>
                                        <th>TGL. PEMBUATAN</th>
                                        <th>NAMA/NO RM</th>
                                        <th>FASKES DITUJU</th>
                                        <th>DOKTER PERUJUK</th>                               
                                        <th>TIPE RUJUKAN</th>
                                        <th>CARA BAYAR</th>
                                        <th>STATUS</th>
                                        <th>AKSI</th>
                                    </tr>
                                </thead>
                                
                                <tbody v-if="(dataList||[]).length">
                                    <tr v-for="(v,k) in (dataList||[])" :key="k">
                                        <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                        <td>{{v.auruj_tanggal_rujuk | moment("DD MMMM YYYY, HH:mm")}}</td>
                                        <td>
                                            <span>{{v.auruj_nama || "-"}}</span><br/>
                                            <span>{{v.auruj_no_rm_asal || "-"}}</span>
                                        </td>
                                        <td>
                                            {{v.auruj_faskes_rujuk_sisrute_text||"-"}}
                                        </td>
                                        <td>
                                            {{v.auruj_dokter_text||"-"}}
                                        </td>
                                        <td>
                                            <!--
                                            {{v.auruj_jenis_rujukan == 2?:}}
                                            -->
                                            RAWAT DARURAT/INAP
                                        </td>
                                        <td>
                                            {{v.mcp_name||"-"}}
                                        </td>
                                        <td>
                                        
                                            <div class="badge badge-primary mb-1" v-if="v.auruj_status == 'B'">
                                                Buat Rujukan BPJS
                                            </div>
                                            <div class="badge badge-warning mb-1" v-if="v.auruj_status == 'M'">
                                                Menunggu
                                            </div>
                                            <div class="badge badge-warning mb-1" v-else-if="v.auruj_status == 'MR'">
                                                Menunggu Respon
                                            </div>
                                            <div class="badge badge-dark mb-1" v-else-if="v.auruj_status == 'A'">
                                                Diterima
                                            </div>
                                            <div class="badge badge-success mb-1" v-else-if="v.auruj_status == 'T'">
                                                Ditolak
                                            </div>
                                            
                                            
                                            <template v-if="v.auruj_status == 'A'">
                                                <div class="badge badge-success mb-1">
                                                    Selesai
                                                </div>
                                            </template>
                                            
                                        </td>    

                                        <td>



                                            <a href="javascript:;" @click="bypassData(v)" v-if="v.auruj_status == 'MR'" class="btn btn-icon btn-sm rounded-circle alpha-warning border-warning text-warning-800" v-b-tooltip.hover.right title="BYPASS Rujukan">
                                            <i class="icon-paperplane"></i>
                                            </a>

                                            <router-link :to="{ name: $router.name , params: { pageSlug : v.aur_id }}"  v-if="v.auruj_status != 'A' && moduleRole('rujukan_sisrute')" class="btn btn-icon btn-sm rounded-circle alpha-warning border-warning text-warning-800" v-b-tooltip.hover.right :title="v.auruj_no_rujukan_sisrute ? 'Edit Rujukan Sisrute': 'Kirimkan Rujukan Sisrute'">
                                            <i :class="v.auruj_no_rujukan_sisrute?'icon-file-plus':'icon-paperplane'"></i>
                                            </router-link>
                                            
                                            <router-link :to="{ name: $router.name , params: { pageSlug : v.aur_id }, query:{isResend: true}}"  v-if="v.auruj_status == 'T' && moduleRole('rujukan_sisrute')" class="btn btn-icon btn-sm rounded-circle alpha-warning border-warning text-warning-800" v-b-tooltip.hover.right title="Kirimkan Rujukan Sisrute Ulang">
                                            <i class="icon-paperplane"></i>
                                            </router-link>

                                            <router-link v-if="(v.auruj_status == 'B' || (v.aur_mcp_id == 137 || v.aur_mcp_id == 138)) && moduleRole('rujukan_bpjs')" :to="{ name: $router.name , params: { pageSlug : v.aur_id }, query:{isRujukan: true}}" class="btn btn-icon btn-sm rounded-circle alpha-success border-success text-success-800" v-b-tooltip.hover.right :title=" v.auruj_is_created_rujukan_bpjs != 'Y'?'Buat Rujukan BPJS':'Edit Rujukan BPJS'"
                                           >
                                            <i class="icon-file-plus"></i>
                                            </router-link>

                                                        
                                            <template v-if="v.auruj_is_created_rujukan_bpjs == 'Y'">
                                                <a @click="generateFile(v.aur_id)" class="btn btn-icon rounded-round btn-sm alpha-info border-info"
                                                data-toggle="tooltip" data-placement="top" v-b-tooltip.hover.right title="Print"><i class="icon-printer"></i></a>
                                            </template>
                                            
                                            <template v-if="v.auruj_is_created_rujukan_bpjs == 'Y' && v.auruj_no_rujukan">
                                                <a @click="generateFileBPJS(v.aur_id)" class="btn btn-icon rounded-round btn-sm alpha-info border-info"
                                                data-toggle="tooltip" data-placement="top" v-b-tooltip.hover.right title="Unduh Dokumen Rujukan BPJS"><i class="icon-download"></i></a>
                                            </template>


                                            <router-link v-if="v.auruj_is_created_rujukan_bpjs == 'Y' && moduleRole('rujukan_bpjs')" :to="{ name: $router.name , params: { pageSlug : v.aur_id }, query:{isRujukan: true, isDetailRujukan: true}}" class="btn btn-icon btn-sm rounded-circle alpha-success border-success text-success-800" v-b-tooltip.hover.right :title=" 'Lihat Rujukan BPJS'"
                                           >
                                            <i class="icon-eye"></i>
                                            </router-link>

                                            
                                            <a href="javascript:;" v-if="v.auruj_status != 'A'" @click="deleteRujukan(v)" class="btn btn-icon btn-sm rounded-circle alpha-danger border-danger text-danger-800" v-b-tooltip.hover.right :title=" 'Batalkan Rujukan '"
                                           >
                                            <i class="icon-bin"></i>
                                            </a>
                                            
                                            <router-link :to="{ name: $router.name , params: { pageSlug : v.aur_id }, query:{isDetail: true}}" class="btn btn-icon btn-sm rounded-circle alpha-info border-info text-info-800" v-b-tooltip.hover.right title="Lihat Detail">
                                            <i class="icon-eye"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="data.data.length == 0">
                                    <tr>
                                        <td colspan="99">
                                        <div class="text-center">
                                            <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                            <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="!data.data">   
                                    <tr>
                                        <td colspan="99"><div class="skeletal-comp"></div></td>
                                    </tr>
                                    <tr>
                                        <td colspan="99"><div class="skeletal-comp"></div></td>
                                    </tr>
                                    <tr>
                                        <td colspan="99"><div class="skeletal-comp"></div></td>
                                    </tr>
                                </tbody>
                            </table>
                                                
                            <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                                <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                                <i class="icon-chevron-left"></i>
                                </a>
                                <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                                <i class="icon-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
        <template v-else>
            <FormRujukan v-if="isRujukan" :row.sync="row" v-bind="passToSub" />
            <Form v-else :row.sync="row" v-bind="passToSub" />
        </template>

    </div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')
    
    import Form from './Form.vue'
    import FormRujukan from './FormRujukan.vue'

    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
            DateRangePicker,
            Form,
            FormRujukan
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            isRujukan(){
                return this.$route.query.isRujukan
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowReg: this.rowReg,
                    mFaskes: this.mFaskes
                }
            },
        },
        data(){
            return {
                idKey:'aures_id',
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                pasienLama: 0,
                pasienBaru: 0,

                
                belumTriase: 0,
                Esi1: 0,
                Esi3: 0,
                Esi5: 0,
                EsiDoa: 0,

                rowEdit : {},
                rowReg : {},

                tabDraft : 'N',
                mFaskes: []
            }
        },
        mounted(){
            this.filter.status_pasien = 'ALL'
            this.filter.status_periksa = 'ALL'
            this.filter.status_jaminan = 'ALL'
            this.filter.status_rujukan = 'ALL'

            this.filter.is_draft = this.tabDraft
            
            this.doConvertDate()      
            this.apiGet()
            
            if(this.isList){
                this.apiGetResume()
            }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            deleteRujukan(v){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menghapus data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if(result.value){
                        let data = v
                        data.type = 'delete-rujukan'

                        this.loadingOverlay = true
                        Gen.apiRest(
                        "/do/" + 'UGDRujukanKeluar', {
                            data: data
                        },
                        "POST"
                        ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                if (result.value) {
                                    this.apiGet()
                                }
                            })
                        }).catch(err => {
                            this.loadingOverlay = false
                            if (err) {
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })

                    }
                })
            },
            generateFileBPJS(id){
                let data = {exptype: 'xlsx', type: "export", id: id}
                let self = this
                self.loadingOverlay = true
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/report/${'downloadRujukanBPJSUGD'}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${'Surat-Rujukan-BPJS'}-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
            generateFile(id){
                let data = {exptype: 'xlsx', type: "export", id: id}
                let self = this
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'SuratRujukan'}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${'Surat-Rujukan'}-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
            bypassData(v){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan membypass data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if(result.value){
                        let data = v
                        data.type = 'bypass-data'

                        this.loadingOverlay = true
                        Gen.apiRest(
                        "/do/" + 'UGDRujukanKeluar', {
                            data: data
                        },
                        "POST"
                        ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                if (result.value) {
                                    this.apiGet()
                                }
                            })
                        }).catch(err => {
                            this.loadingOverlay = false
                            if (err) {
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })

                    }
                })
            },
            periksaPasien(v){
                if(this.user.levelId == 1){
                    this.$router.push({ name: 'UGDAssesment', params: { pageSlug: v.ap_id }, query: {regId: v.aur_id, byPassLevel : this.uPerawatUGD} }).catch(()=>{})
                }else{
                    this.$router.push({ name: 'UGDAssesment', params: { pageSlug: v.ap_id }, query: {regId: v.aur_id} }).catch(()=>{})
                }
            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            windowTableScroller(e){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                        const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                        if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                            this.onScrollPoint = true
                            document.documentElement.scroll(0, this.tableScrollPoint)
                            window.addEventListener('wheel', this.tableScrollFunction, false)
                        }
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: new Date(),
                    endDate: new Date(),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            changeTab(tab){
                this.tabDraft = tab
                this.filter.is_draft = this.tabDraft
                this.doFill()
            },
              apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },

            toDetail(data){
                this.$router.push({ name: 'UGDTriase', params: { pageSlug: data.aures_id } })
                .catch(()=>{})
            },

            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            }
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                if(this.isList){
                    this.apiGetResume()
                }
                
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }
    }
</script>