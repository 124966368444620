<template>
    <div class="content-wrapper">
        <validation-observer ref="VForm">
            <b-form @submit.prevent="doSubmit">
                <div class="content pt-0">
                    <div class="card mb-1">
                    </div>
                    <template>
                        <div class="card mb-1">
                            <div class="card-body bg_card_blue">
                                <div class="row">
                                <div class="col-md-9">
                                    <table class="table table-borderless table-sm text-uppercase">
                                    <tbody>
                                        <tr>
                                            <td width="33%">
                                            <div class="result_tab">
                                                <h4>No. RM</h4>
                                                <p>{{row.auruj_no_rm_asal||"-"}}</p>
                                            </div>
                                            </td>
                                            <td width="33%">
                                            <div class="result_tab">
                                                <h4>Nama Pasien </h4>
                                                <p>{{row.auruj_nama||"-"}}</p>
                                            </div>
                                            </td>
                                            <td width="33%">
                                            <div class="result_tab">
                                                <h4>Kontak</h4>
                                                <p>{{row.auruj_kontak||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                            <div class="result_tab">
                                                <h4>Alamat</h4>
                                                <p>{{row.auruj_alamat||"-"}}</p>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Tempat, Tanggal Lahir</h4>
                                                <p>{{row.auruj_tempat_lahir||"-"}}, {{row.auruj_dob | moment("DD MMMM YYYY")}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Jenis Kelamin</h4>
                                                <p>{{row.auruj_jenis_kelamin == 1 ? "Laki-Laki":"Perempuan"}}</p>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>No. Kartu JKN</h4>
                                                <p>{{row.auruj_no_kartu||"-"}}</p>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>NIK</h4>
                                                <p>{{row.auruj_nik||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                    </template>


                    <div class="card">
                        <template v-if="!isDetailRujukan">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Buat Surat Rujukan</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="form-row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>Faskes Rujukan</label>
                                        <p>{{row.auruj_faskes_rujuk_sisrute_text||"-"}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>Tanggal Rujukan<small class="txt_mandatory">*</small></label>
                                        <div class="input-group">
                                            <div class="input-group mb-3">
                                            <datepicker :disabledDates="disabledDates"
                                                input-class="form-control transparent" placeholder="Tanggal Rujukan"
                                                class="my-datepicker" calendar-class="my-datepicker_calendar"
                                                v-model="row.auruj_tanggal_rujuk">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i
                                                    class="icon-calendar"></i></span>
                                            </div>
                                            </div>
                                        </div>
                                        
                                        <VValidate 
                                            name="Tanggal Rujukan" 
                                            v-model="row.auruj_tanggal_rujuk" 
                                            :rules="{required: 1}"
                                        />
                                        </div>
                                    </div>
                                    
                                    <!--
                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>Rujukan Berlaku Hingga<small class="txt_mandatory">*</small></label>
                                        <div class="input-group">
                                            <div class="input-group mb-3">
                                            <datepicker :disabledDates="disabledDates"
                                                input-class="form-control transparent" placeholder="Rujukan Berlaku Hingga"
                                                class="my-datepicker" calendar-class="my-datepicker_calendar"
                                                v-model="row.auruj_rujukan_exp_date">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i
                                                    class="icon-calendar"></i></span>
                                            </div>
                                            </div>
                                        </div>
                                        
                                        <VValidate 
                                            name="Rujukan Berlaku Hingga" 
                                            v-model="row.auruj_rujukan_exp_date" 
                                            :rules="{required: 1}"
                                        />
                                        </div>
                                    </div>
                                    -->
                                    
                                    <!--
                                    <div class="col-md-12">
                                        <div class="form-group">
                                        <label>Tipe Rujukan<small class="txt_mandatory">*</small></label>
                                        <b-form-radio-group
                                            :options="Config.mr.mRujukanUGD"
                                            v-model="row.auruj_type_rujukan"
                                        />
                                        <VValidate 
                                            name="Tipe Rujukan" 
                                            v-model="row.auruj_type_rujukan" 
                                            :rules="{required: 1}"
                                        />
                                        </div>
                                    </div>
                                    -->
                                    
                                    
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label> Diagnosa<small class="txt_mandatory">*</small></label>
                                            <b-textarea v-model="row.auruj_diagnosa_text" disabled rows="3" cols="3"     class="form-control" placeholder="" spellcheck="false">
                                            </b-textarea>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="poliSelection">Pilih Fakes Rujukan BPJS</label>
                                            <v-select placeholder="Pilih Fakes Rujukan"
                                            v-model="row.auruj_faskes_tujuan"
                                            :options="mFaskes" label="text" :clearable="true"
                                            value="value2"
                                            :reduce="v=>v.value2"></v-select>
                                            <button type="button" class="btn btn-light mr-3 mt-2" @click="openFaskes = true">Tambah Faskes Rujukan BPJS</button>
                                            <VValidate :name="'Faskes Rujukan BPJS'"
                                            v-model="row.auruj_faskes_tujuan"
                                            :rules="{required : 1}" />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                    
                                        <div class="form-group">
                                        <label> Catatan<small class="txt_mandatory">*</small></label>
                                        <b-textarea v-model="row.auruj_catatan" rows="3" cols="3"     class="form-control" placeholder="" spellcheck="false">
                                        </b-textarea>
                                        <VValidate 
                                            name="Catatan" 
                                            v-model="row.auruj_catatan" 
                                            :rules="{required : 1, min : 5}"
                                        />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Surat Rujukan</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                        <label>Tanggal Rujukan</label>
                                        <p>{{row.auruj_tanggal_rujuk | moment("DD MMMM YYYY")}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                        <label>Rujukan berlaku hingga</label>
                                        <p>{{row.auruj_rujukan_exp_date | moment("DD MMMM YYYY")}}</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <div class="form-group">
                                        <label>Diagnosa</label>
                                        <p>{{row.auruj_diagnosa_text||"-"}}</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <div class="form-group">
                                        <label>Catatan</label>
                                        <p>{{row.auruj_catatan||"-"}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        
                        <div class="card-footer">
                            <div class="text-right">
                            <button @click="$router.back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                            <button v-if="!isDetailRujukan" type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-form>
        </validation-observer>

        
        <b-modal v-model="openFaskes" :title="'Tambah Faskes Tujuan'" size="sm" hide-footer>
        <div class="modal-body p-0">
            <div class="mb-3">
            <div class="row">
                
                <div class="col-md-12">
                    <label for="perGravid">Cari Nama Faskes<strong
                    class="text-danger">*</strong></label>
                    <div class="input-group">
                    <b-form-input v-model="faskesSearch"
                        type="text" class="form-control" />
                        <div class="input-group-append">
                        <button @click="searchFaskesData" type="button"
                            class="btn btn-info btn-labeled btn-labeled-left">
                            <b><i class="icon-search4"></i></b>
                            Cari
                        </button>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" v-for="(v,k) in (listFaskesBPJS||[])" :key="k+'radiox'">
                <div class="selection-control">
                    <b-form-checkbox v-model="v['selected']">{{v.kode}} - {{v.nama}}
                    </b-form-checkbox>
                </div>
                </div>

            </div>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3 bg-white" v-if="(listFaskesBPJS||[]).length">
            <div class="text-right">
            <button @click="addFaskes" data-dismiss="modal" class="btn">Selesai</button>
            </div>
        </div>
        </b-modal>
    </div>
</template>



<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'

export default{
    
    components: {
        Datepicker
    }, 
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mFaskes: Array,
  },
  computed:{
    isDetail(){
      return this.$route.query.isDetail ? true : false
    },
    isResend(){
      return this.$route.query.isResend ? true : false
    },
    isDetailRujukan(){
      return this.$route.query.isDetailRujukan ? true : false
    },
    disabledDates() {
        return {
            to: new Date(moment().format('YYYY-MM-DD'))
        }
    },
    selectedFaskesInput() {
        let data = []
        for (let i = 0; i < (this.listFaskesBPJS || []).length; i++) {
            if(this.listFaskesBPJS[i]['selected']){
                data.push(this.listFaskesBPJS[i])
            }
        }
        return data
    },
  },
  data(){
    return {
        mDokter: [],
        mKesadaran: [],

        openFaskes: false,
        faskesSearch : '',
        listFaskesBPJS: [],
    }
  },
  methods: {
    
    searchFaskesData(){
        this.$parent.loadingOverlay = true
        let data = {
            search: this.faskesSearch,
            type: 'search-faskes-data'
        }
        Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
        ).then(res => {
            this.$parent.loadingOverlay = false
            this.listFaskesBPJS = res.data
        })
    },   
    addFaskes(){
        this.$parent.loadingOverlay = true
        let data = {
            faskesData: this.selectedFaskesInput,
            type: 'submit-faskes-data'
        }
        Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
        ).then(res => {
            this.$parent.loadingOverlay = false
            this.$swal({
                icon: 'success',
                title: 'Data Faskes berhasil ditambahkan',
            })
            this.apiGet()
            this.openFaskes = false
        })
    },
    toValidate(val){
      return {...val}
    },
    back(){
        this.$router.back()
    },
    getConfigDynamic(master, value) {
        let text = ''
        if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
            text = master[index]['text']
            }
        }
        return text
    },
    apiGetMaster(){
        Gen.apiRest(
            "/get/"+this.$parent.modulePage+
            '/master', 
            {}
        ).then(res=>{
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            console.log(this.mDokter)
        })
    },
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
            return this.$parent.$swal({
                icon: 'error',
                title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
                if (result.value) {
                    setTimeout(() => {
                        let inv = []
                        let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                        for (let i = 0; i < (el || []).length; i++) {
                            if (el[i].style.display !== 'none') {
                            inv.push(el[i].id)
                            }
                        }
                    }, 500)
                }
            })
        }


        if (success) {
            this.$parent.$swal({
                icon: 'warning',
                title: 'Apakah Anda Yakin akan menyimpan data ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
            }).then(result => {
                if (result.value) {
                    let data = this.row
                    data.type = 'submit-data-rujukan'

                    this.$parent.loadingOverlay = true
                    Gen.apiRest(
                    "/do/" + 'UGDRujukanKeluar', {
                        data: data
                    },
                    "POST"
                    ).then(res => {
                        this.$parent.loadingOverlay = false
                        let resp = res.data
                        resp.statusType = 200
                        this.$swal({
                            title: resp.message,
                            icon: resp.status,
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            if (result.value) {
                                this.$router.push(
                                    { 
                                      name: this.$route.name
                                    })
                                .catch(()=>{})
                            }
                        })
                    }).catch(err => {
                        this.$parent.loadingOverlay = false
                        if (err) {
                            err.statusType = err.status
                            err.status = "error"
                            err.title = err.response?.data?.title
                            err.message = err.response?.data?.message
                            err.messageError = err.message
                        }
                        this.$parent.doSetAlertForm(err)
                    })

                }
            })
        }
      })
    }
  },
  mounted() {
      this.apiGetMaster()
  },
}
</script>